import React from "react";
import "./progessbar.css"; 

const ProgressBar = ({ currentPopularity }) => {
  let progress = 0;
  let bgColor = "grey";
  let text = "Closed";

  if (currentPopularity > 1 && currentPopularity <= 40) {
    progress = currentPopularity;
    bgColor = "green";
    text = "Calm";
  } else if (currentPopularity > 40 && currentPopularity <= 80) {
    progress = currentPopularity;
    bgColor = "yellow";
    text = "Busy";
  } else if (currentPopularity > 80 && currentPopularity <= 99) {
    progress = currentPopularity;
    bgColor = "orange";
    text = "Very Busy";
  }
  else if (currentPopularity === 100) {
    progress = currentPopularity;
    bgColor = "red";
    text = "packed";
  }

  return (
     <>
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%`, backgroundColor: bgColor }}></div>
    </div>
      <div className="progress-text">{text}</div>
    </>
  );
};

export default ProgressBar;
