import React, { useState } from "react";
import { Navbar, Nav, Dropdown, DropdownButton, Form } from "react-bootstrap";
import "../Components/NavbarMD.css";

const NavbarComponent = ({ filter, handleFilterChange }) => {
  const [showFilters, setShowFilters] = useState(false);

  const getCurrentPopularityTitle = () => {
    switch (filter.currentPopularity) {
      case "closed":
        return "Closed";
      case "calm":
        return "Calm";
      case "busy":
        return "Busy";
      case "very busy":
        return "Very Busy";
      case "packed":
        return "Packed";
      default:
        return "Current Popularity";
    }
  };

  const getPlaceTypeTitle = () => {
    switch (filter.placeType) {
      case "bar":
        return "Bar";
      case "restaurant":
        return "Restaurant";
      case "house":
        return "House";
      default:
        return "Place Type";
    }
  };

  const getPriceRangeTitle = () => {
    switch (filter.priceRange) {
      case "cheap":
        return "Cheap";
      case "moderate":
        return "Moderate";
      case "expensive":
        return "Expensive";
      case "luxury":
        return "Luxury";
      default:
        return "Price Range";
    }
  };

  const getDistanceTitle = () => {
    switch (filter.distance) {
      case "less than 2KM":
        return "Less than 2KM";
      case "less than 5KM":
        return "Less than 5KM";
      case "less than 10KM":
        return "Less than 10KM";
      default:
        return "Distance";
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Navbar bg="dark" expand="lg" className="p-3 mb-4" variant="dark">
      <Navbar.Brand className="mx-auto text-light navbar-brand">
        Find Your Dream Place
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleFilters} />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className={`mx-auto d-flex align-items-center ${showFilters ? "show-filters" : ""}`}>
          <Form inline>
            <DropdownButton
              id="currentPopularity"
              title={getCurrentPopularityTitle()}
              className="m-2 dropdown-button"
              variant="light"
            >
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "currentPopularity", value: "" } })}>
                <em>All</em>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "currentPopularity", value: "closed" } })}>
                Closed
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "currentPopularity", value: "calm" } })}>
                Calm
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "currentPopularity", value: "busy" } })}>
                Busy
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "currentPopularity", value: "very busy" } })}>
                Very Busy
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "currentPopularity", value: "packed" } })}>
                Packed
              </Dropdown.Item>
            </DropdownButton>
          </Form>
          <Form inline>
            <DropdownButton
              id="placeType"
              title={getPlaceTypeTitle()}
              className="m-2 dropdown-button"
              variant="light"
            >
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "placeType", value: "" } })}>
                <em>All</em>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "placeType", value: "bar" } })}>
                Bar
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "placeType", value: "restaurant" } })}>
                Restaurant
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "placeType", value: "house" } })}>
                House
              </Dropdown.Item>
            </DropdownButton>
          </Form>
          <Form inline>
            <DropdownButton
              id="priceRange"
              title={getPriceRangeTitle()}
              className="m-2 dropdown-button"
              variant="light"
            >
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "priceRange", value: "" } })}>
                <em>All</em>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "priceRange", value: "cheap" } })}>
                Cheap
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "priceRange", value: "moderate" } })}>
                Moderate
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "priceRange", value: "expensive" } })}>
                Expensive
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "priceRange", value: "luxury" } })}>
                Luxury
              </Dropdown.Item>
            </DropdownButton>
          </Form>
          <Form inline>
            <DropdownButton
              id="distance"
              title={getDistanceTitle()}
              className="m-2 dropdown-button"
              variant="light"
            >
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "distance", value: "" } })}>
                <em>All Over</em>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "distance", value: "less than 2KM" } })}>
                Less than 2KM
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "distance", value: "less than 5KM" } })}>
                Less than 5KM
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange({ target: { name: "distance", value: "less than 10KM" } })}>
                Less than 10KM
              </Dropdown.Item>
            </DropdownButton>
          </Form>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;