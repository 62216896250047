import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "./progressbar";
import "./cardComponent.css";

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const decimalPart = rating % 1;
  let halfStar = 0;
  let emptyStars = 0;

  if (decimalPart >= 0.75) {
    halfStar = 1;
  } else if (decimalPart >= 0.25) {
    halfStar = 0.5;
  }

  emptyStars = 5 - fullStars - halfStar;

  return (
    <span className="stars">
      {"★".repeat(fullStars)}
      {halfStar === 1 && "★"}
      {halfStar === 0.5 && "☆"}
      {"☆".repeat(emptyStars)}
    </span>
  );
};

const PlaceCard = ({ place, userLocation, calculateDistance }) => {
  let distance;
  if (userLocation) {
    distance = calculateDistance(
      userLocation.latitude,
      userLocation.longitude,
      place.Latitude,
      place.Longitude
    );
  }

  return (
    <div className="review rounded">
      <div className="cardContainer rounded d-flex align-items-center justify-content-between">
        <div className="cardContainerLeft">
          <h1>{place.PlaceName}</h1>
          <div className="ratingWithStars">
          <div className="rating">
            {renderStars(place.Rating)}
          </div>
          <p>
            {place.Rating} ({place.Rating_n})
          </p>
          </div>
          <p>{distance ? `Distance: ${distance.toFixed(2)} km` : "Distance: N/A"}</p>
          <p>{place.Address}</p>
        </div>
        <div className="cardContainerRight">
          <img
            src={place.GooglePlaceImage}
            height={"120px"}
            width={"150px"}
            alt=""
          />
          <a
            className="btn btn-primary btn-sm mt-1"
            href={`${place.GoogleMapLocation}`}
            target="_blank"
            rel="noreferrer"
          >
            View on Google Maps
          </a>
        </div>
      </div>
      <ProgressBar currentPopularity={place.CurrentPopularity} />
    </div>
  );
};

PlaceCard.propTypes = {
  place: PropTypes.object.isRequired,
  userLocation: PropTypes.object,
  calculateDistance: PropTypes.func.isRequired,
};

export default PlaceCard;
