import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import NavbarComponent from "../../Components/Navbar";
import PlaceCard from "../../Components/CardComponent";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import "./index.css";

const Index = () => {
  const [places, setPlaces] = useState([]);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [placesPerPage] = useState(30);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    currentPopularity: "",
    placeType: "",
    priceRange: "",
    distance: "",
  });

  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error("Error getting user location:", error);
          },
          { timeout: 10000 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchUserLocation();
  }, []);

  useEffect(() => {
    fetchPlaces();
  }, []);

  useEffect(() => {
    if (userLocation && places.length > 0) {
      applyFilters();
    }
  }, [userLocation, places, filter]);

  const fetchPlaces = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://bixid.in/places");
      // const response = await axios.get("http://localhost:5V000/places");
      setPlaces(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const indexOfLastPlace = currentPage * placesPerPage;
  const indexOfFirstPlace = indexOfLastPlace - placesPerPage;
  const currentPlaces = filteredPlaces.slice(indexOfFirstPlace, indexOfLastPlace);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const applyFilters = useCallback(() => {
    let filteredData = [...places];

    if (filter.distance && userLocation) {
      const maxDistance = parseFloat(filter.distance.split(" ")[2]);
      filteredData = filteredData.filter((place) => {
        const distance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          place.Latitude,
          place.Longitude
        );
        return distance <= maxDistance;
      });
    }

    if (filter.currentPopularity) {
      if (filter.currentPopularity === "closed") {
        filteredData = filteredData.filter(
          (place) => place.CurrentPopularity === 0
        );
      } else if (filter.currentPopularity === "calm") {
        filteredData = filteredData.filter(
          (place) =>
            place.CurrentPopularity > 1 && place.CurrentPopularity <= 40
        );
      } else if (filter.currentPopularity === "busy") {
        filteredData = filteredData.filter(
          (place) =>
            place.CurrentPopularity > 40 && place.CurrentPopularity <= 80
        );
      } else if (filter.currentPopularity === "very busy") {
        filteredData = filteredData.filter(
          (place) =>
            place.CurrentPopularity > 80 && place.CurrentPopularity <= 99
        );
      } else if (filter.currentPopularity === "packed") {
        filteredData = filteredData.filter(
          (place) => place.CurrentPopularity >= 100
        );
      }
    }

    const placeTypeMapping = {
      bar: ["bar", "pub", "club"],
      restaurant: ["restaurant"],
      house: ["house"],
    };

    if (filter.placeType) {
      const filterSubstrings =
        placeTypeMapping[filter.placeType.toLowerCase()] || [];
      filteredData = filteredData.filter((place) =>
        filterSubstrings.some((substring) =>
          place.PlaceType.toLowerCase().includes(substring)
        )
      );
    }

    if (filter.priceRange) {
      const priceRanges = {
        cheap: "$",
        moderate: "$$",
        expensive: "$$$",
        luxury: "$$$$",
      };
      filteredData = filteredData.filter((place) =>
        place.PriceRange.includes(priceRanges[filter.priceRange])
      );
    }

    setFilteredPlaces(filteredData);
  }, [filter, places, userLocation]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="page">
          <NavbarComponent filter={filter} handleFilterChange={handleFilterChange} />
          <div className="card-container">
            {currentPlaces.map((place, index) => (
              <PlaceCard
                key={index}
                place={place}
                userLocation={userLocation}
                calculateDistance={calculateDistance}
              />
            ))}
          </div>
            <Pagination
              placesPerPage={placesPerPage}
              totalPlaces={filteredPlaces.length}
              paginate={paginate}
              currentPage={currentPage}
            />
        </div>
      )}
    </div>
  );
};

export default Index;