import React from "react";
import { Pagination } from "react-bootstrap";
import "./pagination.css";

const PaginationComponent = ({ placesPerPage, totalPlaces, paginate, currentPage }) => {
  const totalPages = Math.ceil(totalPlaces / placesPerPage);

  const handlePageChange = (page) => {
    paginate(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.Prev
          onClick={() => {
            if (currentPage > 1) {
              handlePageChange(currentPage - 1);
            }
          }}
          disabled={currentPage === 1}
        >
          Previous 
        </Pagination.Prev>
        <Pagination.Next
          onClick={() => {
            if (currentPage < totalPages) {
              handlePageChange(currentPage + 1);
            }
          }}
          disabled={currentPage === totalPages}
        >
          Next
        </Pagination.Next>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
