import React from 'react';
import './Loader.css'; 

const Loader = () => {
  return (
    <div className="loader-container">
      <div id="load">
  <div>G</div>
  <div>N</div>
  <div>I</div>
  <div>D</div>
  <div>A</div>
  <div>O</div>
  <div>L</div>
</div>
    </div>
  );
};

export default Loader;
